<template>
  <a-layout class='index animated fadeIn'>
    <a-card>
      <a-form ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item ref="role_name" label="角色名称" name="role_name">
          <a-input v-model:value="form.role_name" />
        </a-form-item>
        <a-form-item ref="description" label="角色描述" name="description" help='(最多10个字)'>
          <a-input v-model:value="form.description" />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 8, offset: 2 }">
          <a-button type="primary" @click="onSubmit">
            保存
          </a-button>
        </a-form-item>
      </a-form>
    </a-card>
  </a-layout>
</template>
<script>
import { ref, onMounted, reactive, toRefs } from "vue";
// 初始化默认筛选项数值
let defForm = {
  role_name: "",
  description: "",
  menu_ids: [],
};
export default {
  setup() {
    //pass
    const form = ref({ ...defForm });
    const state = reactive({
      labelCol: { span: 2 },
      wrapperCol: { span: 8 },
      value: [],
      rules: {
        role_name: [
          {
            required: true,
            message: "请输入角色名称",
          },
        ],
      },
    });
    const ruleForm = ref();
    const onSubmit = () => {
      ruleForm.value
        .validate()
        .then(() => {})
        .catch((error) => {});
    };
    return {
      form,
      ...toRefs(state),
      ruleForm,
      onSubmit,
    };
  },
};
</script>
